import React from "react";
import { useState,useEffect } from "react";
import Modal from 'react-modal';
import { RxCross2 } from "react-icons/rx";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import axios from 'axios';
import validator from 'validator';
import './update_password.css';
import Alert from 'react-bootstrap/Alert';
import { RxInfoCircled } from "react-icons/rx";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Popover from 'react-bootstrap/Popover';
import { Toaster } from 'react-hot-toast';
import { toast } from 'react-hot-toast';



function UpdatePassword(props) {
    const token = localStorage.getItem('user')
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword]=useState("");
    const [oldPasswordVisible, setOldPasswordVisible] = useState(false);
    const [newPasswordVisible, setNewPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [oldPasswordMessage, setOldPasswordMessage] = useState("");
    const [newPasswordMessage, setNewPasswordMessage] = useState("");
    const [confirmPasswordMessage, setConfirmPasswordMessage] = useState("");
    const [successMessage,setSucessMessage]=useState("")
    const [expired, setExpired] = useState(false);
    const [tooltip,setTooltip] = useState(false)

    useEffect(() => {
        if (successMessage) {
          const timeoutId = setTimeout(() => {
            setSucessMessage(null);
          }, 5000); // 5000ms = 5 seconds
    
          // Clear the timeout if the component is unmounted or the message changes
          return () => clearTimeout(timeoutId);
        }
      }, [successMessage]);

    useEffect(() => {
        setConfirmPasswordMessage("")
    }, [confirmPassword,props.close]);
    useEffect(() => {
        setNewPasswordMessage("")
    }, [newPassword,props.close]);
    useEffect(() => {
        setOldPasswordMessage("")
    }, [oldPassword,props.close]);
    const handleUpdate = async (e)=>{
        e.preventDefault();
        
        if(oldPassword ==="" && newPassword === "" && confirmPassword ===""){
            setOldPasswordMessage("Please enter old password")
            setNewPasswordMessage("Please enter new password")
            setConfirmPasswordMessage("Please enter confirm password")
            return

        }
        if (oldPassword === ""){ 
            setOldPasswordMessage("Please enter old password")
            return;
        }
        if(newPassword==="" ){
            setNewPasswordMessage("Please enter new password")
            return
        }
        if(confirmPassword=== ""){
            setConfirmPasswordMessage("Please enter confirm password")
            return
        }
        
        var lowerCase = /[a-z]/g;
        var upperCase = /[A-Z]/g;
        var numbers = /[0-9]/g;
        var specialChars = /[^a-zA-Z0-9]/g;
        if (!newPassword.match(lowerCase)) {
           setNewPasswordMessage("Password should contains lowercase letters!");
           return
        } else if (!newPassword.match(upperCase)) {
            setNewPasswordMessage("Password should contain uppercase letters!");
            return
        } else if (!newPassword.match(numbers)) {
            setNewPasswordMessage("Password should contains numbers also!");
            return
        } else if(!newPassword.match(specialChars)){
            setNewPasswordMessage("Password should contains special characters!");
            return
        }else if (newPassword.length < 8) {
            setNewPasswordMessage("Password length should be more than 8.");
            return
        } 
        if (newPassword !== confirmPassword){
            setConfirmPasswordMessage("Passwords do not match!");
            return
        }
        const data ={
            "data": {
                "old_password": oldPassword,
                "new_password": confirmPassword
            },
            "toUpdate": "password"
        };
  
  const config = {
         method: "post",
         url: "http://68.178.172.104:5010/api/settings",
         headers: { 
             'Authorization': `Bearer ${token}`,  
         },
         data : data  
       };
       try {
        await axios.request(config);
        toast.success("Password updated successfully!");
    } catch (error) {
        toast.error("Password not updated");
    }
       try{
  const response=await axios.request(config)
        if(response.data[1] === 400 || response.data.message==="Wrong old password!"){
            setOldPasswordMessage('Wrong old password!')
        }
        else if(response.data[1] === 200 || response.data.message === "Password updated successfully!"){
            setSucessMessage("Password updated successfully!")
        setConfirmPassword("")
        setNewPassword("")
        setOldPassword("")
        }
        else {
            toast.error("Something Went Wrong!");
        }
      }
      catch (error) {
        toast.error("Something Went Wrong!");

        if (error.response.status === 401) {
          setExpired(true);
        }
    }
}

const [inputType, setInputType] = useState("password");

const toggleInputType = () => {
  setInputType((prevType) => (prevType === 'password' ? 'text' : 'password'));
};
    return ( 
    <div>
        <Modal isOpen={props.state} contentLabel="Example Modal" ariaHideApp={false} style={{content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        background:'white',
        width:'380px',
        height:'420px',
        maxHeight:'fit-content',
        padding:' 40px 30px',
        overflow:'auto',
        borderRadius: '10px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        },}}>
            <form style={{display:'inline',flexDirection:'row'}}>
                <div className='label-of-default-expense'>
                    <label className='lable-of-default-expense'>Update Password</label>
                    <RxCross2 onClick={props.close} />
                </div>
                
                <div className="update-password-div">
                    <div className="password-input-container"> 
                    <input  
                        type={oldPasswordVisible ?"text":"password" }
                        value={oldPassword}
                        onChange={(e)=>setOldPassword(e.target.value)} 
                        placeholder="Old password" 
                        className='input'/>
                        
                        <span
                             onClick={()=>setOldPasswordVisible(!oldPasswordVisible)}
                            className="eye-icon"
                          >
                            {oldPasswordVisible ? <FaEye/> : <FaEyeSlash />}
                          </span>
                    </div>
                    <span className="error-message">{oldPasswordMessage}</span>
                    <div className="password-input-container">
                        <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Popover id="popover-contained">
                                  <Popover.Body>
                                  Password must have minimum of 8 charecter and atleast 1 uppercase, 1 lowercase,1 special character and 1 digit
                                  </Popover.Body>
                                </Popover>}>
                            
                            <input 
                                type={newPasswordVisible ?"text":"password" }
                                value={newPassword}
                                style={{marginTop:'25px'}}
                                onChange={(e)=>setNewPassword(e.target.value)}
                                placeholder="New password" 
                                className='input'/>
                        </OverlayTrigger>
                        
                        <span
                             onClick={()=>setNewPasswordVisible(!newPasswordVisible)}
                            className="eye-icon"
                          >
                            {newPasswordVisible ? <FaEye/> : <FaEyeSlash />}
                          </span>
                        </div>
                        <span className="error-message">{newPasswordMessage}</span>
                    <div className="password-input-container">
                    <input 
                        type={confirmPasswordVisible ?"text":"password" }
                        placeholder="Confirm password" 
                        value={confirmPassword}
                        style={{marginTop:'25px'}}
                        onChange={(e)=>setConfirmPassword(e.target.value)}
                        className='input'/>
                        <span
                             onClick={()=>setConfirmPasswordVisible(!confirmPasswordVisible)}
                            className="eye-icon"
                          >
                            {confirmPasswordVisible ? <FaEye/> : <FaEyeSlash />}
                          </span>
                        </div>
                        <span className="error-message">{confirmPasswordMessage}</span>

                </div>
                
                <span style={{fontSize:'14px',color:"#30B664",display:'flex',justifyContent:'center'}}>{successMessage}</span>
                <div className="update-password-submit-button-div">
                <button className="update-password-submit" onClick={handleUpdate}>Update</button>
                </div>
            </form>
            <Toaster
  position="bottom-center"
  reverseOrder={false}
/>
        </Modal>
        
    </div> 
    );
}

export default UpdatePassword;