import Modal from 'react-modal';
import React from 'react';
import { RxCross2 } from "react-icons/rx";
import react,{useEffect,useState,useRef} from 'react';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import './default_expense.css';
import { Toaster } from 'react-hot-toast';
import { toast } from 'react-hot-toast';

const token = localStorage.getItem('user')

function Budget_limit(props) {
    const [limit,setLimit]=useState("");
    const [Message,setMessage]=useState("")

    useEffect(() => {
        const fetchdata = async ()=>{
            const data = {
                "data": {
                  "limit": ""
                },
                "toUpdate": "budget_limit"
              };

              const config = {
                method: "post",
                url: "http://68.178.172.104:5010/api/settings",
                headers: { 
                    'Authorization': `Bearer ${token}`,  
                },
                data : data  
              };
          
              try {
                const response = await axios.request(config);
                setLimit(response.data.limit);  
            } catch (error) {
                toast.error("Something Went Wrong!");
            }
    }
        fetchdata();
        
    }, []);
    const handleSave= async (e)=>{
        e.preventDefault();
        if(limit==0 ||  limit==""){
           setMessage("Please enter a valid number.")
           return
        }
        const data_limit = parseInt(limit)
        const data = {
            "data": {
              "limit": data_limit,
            },
            "toUpdate": "budget_limit"
          };
         
    const config = {
           method: "post",
           url: "http://68.178.172.104:5010/api/settings",
           headers: { 
               'Authorization': `Bearer ${token}`,  
           },
           data : data  
         };
         

try {
  const response=await axios.request(config)
  //setLimit(response.data.limit)
    toast.success("Budget Limit is set!!");
} catch (error) {
  toast.error("Budget limit not saved");
}


    }
    return ( <div>
       < Modal isOpen={props.state} contentLabel="Example Modal" ariaHideApp={false} style={{content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    background:'white',
    width:'380px',
    height:'420px',
    maxHeight:'fit-content',
    padding:' 40px 30px',
    overflow:'auto',
    borderRadius: '10px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },}}> 
  
  <div className='label-of-default-expense'><label className='lable-of-default-expense'>Budget Limit</label><RxCross2 onClick={props.close} /></div><br/>
  <div style={{padding:'30px 0px'}}>
    <input className='input' type='number' style={{width:'316px'}} placeholder='Expense Limit ' value={limit} onChange={(e)=>setLimit(e.target.value)}/></div>
  <div style={{display:'flex',justifyContent:'center',padding:'30px'}}><button className='save-button' onClick={handleSave} >Save</button></div>
  <Toaster
  position="bottom-center"
  reverseOrder={false}
/>
    </Modal>
    </div> );
}

export default Budget_limit;