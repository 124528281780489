import moment from 'moment';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import { useNavigate } from "react-router-dom";
import './top_nav.css'
import { useState } from 'react';
import Default_expense from './settings/default_expense';
import UpdatePassword from './settings/update_password';
import Notificationsettings from './settings/Notification_settings';
import { RxCross2 } from "react-icons/rx";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import Verify_email from './settings/verify_email';
import Verify_whatsapp from './settings/verify_whatsapp';
import Update_email from './settings/update_email';
import Update_whatsapp from './settings/update_whatsapp';
import Default_reminder from './settings/default_reminder';
import NOtification from './Notification';
import Budget_limit from './settings/budget_limit';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { pdfjs } from 'react-pdf';  
import { toolbarPlugin, TransformToolbarSlot } from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';



pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function ContainerExample(props) {
  const [defaultState,setDefaultState]= useState(false)
  const [updatepassword,setUpdatePassword]=useState(false)
  const [notificataionSettings,setNotificationSettings] = useState(false)
  const [verifyemail,setVerifyEmail]= useState(false)
  const [verifywhatsapp,setVerifyWhatsapp]= useState(false);
  const [updateEmail,setUpdateEmail]= useState(false);
  const [updateWhatsapp,setUpdateWhatsapp]=useState(false);
  const [default_reminder,setDefaultReminder]= useState(false);
  const [budgetLimit,setBudgetLimit]= useState(false);
  const [showPDF, setShowPDF] = useState(false); // State to handle PDF display
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const toolbarPluginInstance = toolbarPlugin();

  const { Toolbar } = toolbarPluginInstance;

  const navigate = useNavigate();
  const Name = localStorage.getItem('name')
  const email_verified = localStorage.getItem('email_verified') === "true"
  //const email_verified = false
  //const whatsapp_verified = false
  const whatsapp_verified = localStorage.getItem('whatsapp_verified') === "true"
  const handleDefaultExpense =()=>{
    setDefaultState(true);
  }
  const handleBudgetlimit =()=>{
    setBudgetLimit(true);
  }
  const handleDefaultReminder = ()=>{
    setDefaultReminder(true)
  }
  const handleUpdatePassword =()=>{
    setUpdatePassword(true)
  }
  const handleNotificationSettings = ()=>{
    setNotificationSettings(true)
  }
  const handleVerifyEmail = ()=>{
    setVerifyEmail(true)
  }
  const handleverifywhatsapp = ()=>{
    setVerifyWhatsapp(true)
  }
  const handleUpdateEmail = ()=>{
    setUpdateEmail(true)
  }
  const handleUpdateWhatsapp = ()=>{
    setUpdateWhatsapp(true)
  }
  const togglePDF = () => {
    setShowPDF(!showPDF);
  };
  const logout=(e)=>{
    e.preventDefault();
    localStorage.removeItem('user');
    localStorage.removeItem('name');
    localStorage.removeItem('default_expense');
    localStorage.removeItem('email_verified');
    localStorage.removeItem('whatsapp_verified');
    localStorage.removeItem('whatsapp_notification')
    localStorage.removeItem('email_notification');
    navigate("/login")
}
  return (<div className='top-bar'>
<Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand><Image src='Frame 427320367.png' style={{paddingRight:'20px'}}/>Hello {Name}!</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-collapse" />
        <Navbar.Collapse id="navbar-collapse">
          <Nav className="me-auto">
           
          </Nav>
          <Nav>
            <Nav.Link className='top-nav-link' href="#deets" id="date-month">{moment().format('DD MMM YYYY')}</Nav.Link>
             {/* <NavDropdown  align="end" title={<div className='bell'><Image src='charm_bell.svg' id="notification-bell"/></div>} show-arrow={false} id="notification-icon" className="collapsible-nav-dropdown">
             <div style={{width:'379px',height:'350px',overflow:'auto'}}>
             <NOtification />
             </div>
              

            </NavDropdown> */}
            <NavDropdown align="end"  title={<div className='setting'><Image src='solar_settings-linear.svg' className='solar-settings' /></div>} id='settings-icon' className="collapsible-nav-dropdown">
              <div className='dropdown-in-top-nav'>
                <label className='label-of-dropdown-in-top-nav'><span style={{fontSize:'20px'}}>Settings</span><RxCross2/></label>
                <div style={{padding:'15px 30px'}}>
              
              <NavDropdown.Item  className='dropdown-item-in-top-nav' onClick={handleUpdatePassword}>Update Password<MdOutlineKeyboardArrowRight /></NavDropdown.Item>
              <UpdatePassword
              state={updatepassword}
              close={()=>setUpdatePassword(false)}
              />
              
              <NavDropdown.Item className='dropdown-item-in-top-nav' onClick={handleUpdateEmail}>Update Email<MdOutlineKeyboardArrowRight /></NavDropdown.Item>
              <Update_email
              state={updateEmail}
              close={()=>setUpdateEmail(false)}/>
              {email_verified ? null:<>
              <NavDropdown.Item  style={{padding:'0px 16px'}}><span className='verify-on-top-nav' onClick={handleVerifyEmail} >Verify email</span></NavDropdown.Item>
              <Verify_email
              state={verifyemail}
              close={()=>setVerifyEmail(false)}
              
              
                />
              </>
              }
              
              
              <NavDropdown.Item  className='dropdown-item-in-top-nav' onClick={handleUpdateWhatsapp} >Update Whatsapp<MdOutlineKeyboardArrowRight /></NavDropdown.Item>
              <Update_whatsapp
              state={updateWhatsapp}
              close={()=>setUpdateWhatsapp(false)}
              />
              {whatsapp_verified ? null:<>
              <NavDropdown.Item style={{padding:'0px 16px'}}><span className='verify-on-top-nav' onClick={handleverifywhatsapp}>verify Whatsapp</span></NavDropdown.Item>
                <Verify_whatsapp
                state={verifywhatsapp}
                close={()=>setVerifyWhatsapp(false)}
                />
              </>
              }
              
              <NavDropdown.Item  className='dropdown-item-in-top-nav' onClick={handleDefaultExpense}>Default Expense<MdOutlineKeyboardArrowRight /></NavDropdown.Item>
              <Default_expense 
              state={defaultState}
              close={()=>setDefaultState(false)}
              />
              <NavDropdown.Item  className='dropdown-item-in-top-nav' onClick={handleDefaultReminder}>Default Reminder<MdOutlineKeyboardArrowRight /></NavDropdown.Item>
              <Default_reminder 
              state={default_reminder}
              close={()=>setDefaultReminder(false)}
              />

              <NavDropdown.Item  className='dropdown-item-in-top-nav' onClick={handleBudgetlimit}>Budget Limit<MdOutlineKeyboardArrowRight /></NavDropdown.Item>
              <Budget_limit
              state={budgetLimit}
              close={()=>setBudgetLimit(false)}/>
              {/* <NavDropdown.Item  className='dropdown-item-in-top-nav'  onClick={handleNotificationSettings}>
                Notification Settings<MdOutlineKeyboardArrowRight /></NavDropdown.Item>
              <Notificationsettings
              state={notificataionSettings}
              close={()=>setNotificationSettings(false)}
              />   */}
              
              <div href="#action/3.6" id='logout-button-div' >
                <button onClick={logout} className='logout-button'>Logout</button>
              </div>
              </div>
              </div>
            </NavDropdown>
            <button className='Help-button' style={{ background: '#5268F01A', border: 'none' }} onClick={togglePDF}>
                <Image src='question.png' style={{ width: '32px', height: '32px' }} id="notification-icon" />
              </button>
              {showPDF && (
  <div className='pdf-viewer' style={{
    width: '50%',
    overflow:'visible',
    maxHeight:'800px',
    height:'700px',
    position: 'absolute',
    top: '50px',
    right: '50px',
    zIndex: '1000',
    backgroundColor: 'white',
    border: '1px solid #ccc',
    padding: '10px',
    borderRadius: '5px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'
  }}>
    <Worker workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`}>
    
      <Viewer style={{maxHeight:'800px'}} fileUrl='Help_Planner.pdf' plugins={[toolbarPluginInstance]}
       />
  <button onClick={togglePDF} style={{
                    display: 'block',
                    margin: '10px auto 0',
                    padding: '5px 10px',
                    backgroundColor: '#30B664'
                    ,
                    border: 'none',
                    borderRadius: '5px',
                    color: 'white',
                    cursor: 'pointer'
                  }}>Close</button>
    </Worker>
    
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
      <Toolbar>
        {(props) => {
          console.log('Toolbar props:', props); // Log the props to debug
          const { ZoomIn, ZoomOut, GoToNextPage, GoToPreviousPage } = props.slot || {};
          // Check if props.slot is defined and has the necessary properties
          if (!props.slot) {
            console.error('props.slot is undefined');
            return null;
          }
          if (!ZoomIn || !ZoomOut || !GoToNextPage || !GoToPreviousPage) {
            console.error('One or more expected properties are missing from props.slot');
            return null;
          }
          return (
            <div style={{ display: 'flex', gap: '8px' }}>
              
              <GoToPreviousPage />
              <ZoomOut />
              <ZoomIn />
              <GoToNextPage />
            </div>
          );
        }}
        
      </Toolbar>
      
    </div>
    
  </div>
)}

              {/* {showPDF && (
                <div className='pdf-viewer' style={{
                  width: '50%',
                  position: 'absolute',
                  top: '50px',
                  right: '50px',
                  zIndex: '1000',
                  backgroundColor: 'white',
                  border: '1px solid #ccc',
                  padding: '10px',
                  borderRadius: '5px',
                  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'
                }}>
                  <Worker workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`}>
                    <Viewer fileUrl='Help_Planner.pdf' plugins={[toolbarPluginInstance]} />
                  </Worker>
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                    <Toolbar>
                      {(props) => {
                        const { ZoomIn, ZoomOut, GoToNextPage, GoToPreviousPage } = props.slot;
                        return (
                          <div style={{ display: 'flex', gap: '8px' }}>
                            <GoToPreviousPage />
                            <ZoomOut />
                            <ZoomIn />
                            <GoToNextPage />
                          </div>
                        );
                      }}
                    </Toolbar>
                  </div>
                </div>
              )} */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default ContainerExample;