import React from "react";
import axios from "axios";
import { useState,useEffect } from "react";
import Modal from 'react-modal';
import { RxCross2 } from "react-icons/rx";
import './verify_email.css';
import { Toaster } from 'react-hot-toast';
import { toast } from 'react-hot-toast';

function Verify_email(props) {
    const [email,setEmail]=useState("");
    const token = localStorage.getItem('user');
    const [emailClose,setEmailClose]=useState(props.close)
    const [otp,setOtp]=useState("")
    const [otpState,setOtpState]=useState(false);
    const [otpMessage,setOtpMessage]= useState("")
    const [expired, setExpired] = useState(false);
    const [errormessage,setErrorMessage]=useState("");
    
    useEffect(() => {
        if (otpMessage) {
          const timeoutId = setTimeout(() => {
            setOtpMessage(null);
            setOtpState(false);
            props.close();
          }, 5000); // 5000ms = 5 seconds
    
          // Clear the timeout if the component is unmounted or the message changes
          return () => clearTimeout(timeoutId);
        }
      }, [otpMessage]);

      useEffect(() => {
        setErrorMessage("")
      }, [email]);

    const handleEmail = async (e)=>{
        e.preventDefault();
        
        let mail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      if (!mail.test(email)) {
        setErrorMessage('Email not valid!')
        return
      }
        if(email.length===0){
            setErrorMessage("Please enter email")
            return
        }
        const data ={
            "data":{"email":email},
            "on":"email"
        }
  const config = {
         method: "post",
         url: "http://68.178.172.104:5010/api/request_otp",
         
         data : data  
       };
       try{
        const response=await axios.request(config)
        
        if(response.data.status===200){
            setEmailClose(false)
            setOtpState(true)
        }
        else {
            toast.error("Something Went Wrong!");
        }
      }
      catch (error) {
        toast.error("Something Went Wrong!");

    }


    }
    const handleOTP = async (e) =>{
        e.preventDefault();
        if(otp.length===0){
            setOtpMessage("Please enter OTP")
            return
        }
        if(otp.length>6 || otp.length < 6){
            setOtpMessage("OTP shoud be 6 digits")
            return
        }
        const data ={
            "on": "email",
            "otp": parseInt(otp),
            "email": email
        }
  const config = {
         method: "post",
         url: "http://68.178.172.104:5010/api/verify_otp",
         headers: { 
             'Authorization': `Bearer ${token}`,  
         },
         data : data  
       };
       try{
        const response=await axios.request(config)
       
        if(response.data.status===200){
            toast.success("Email verified successfully!");

            // setOtpMessage("Email verified sucessfully")
            setEmail("")
            setOtp("")
            localStorage.setItem("email_verified",true)
            
        }
        else if(response.data.status===403 || response.data.message==="invalid otp"){
            setOtpMessage("Invalid OTP")
        }
        else {
            toast.error("Something Went Wrong!");
        }
      }
      catch (error) {
        toast.error("Something Went Wrong!");
    }

    }
    return ( <div>
         <Modal isOpen={props.state} contentLabel="Example Modal" ariaHideApp={false} style={{content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        background:'white',
        width:'380px',
        height:'420px',
        maxHeight:'fit-content',
        padding:' 40px 30px',
        overflow:'auto',
        borderRadius: '10px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        },}}>
            <form>
                <div className='label-of-default-expense'>
                    <label className='lable-of-default-expense'>Email verification</label>
                    <RxCross2 onClick={props.close} />
                </div>
                <div className="input-div-in-email-verify">
                    <input 
                        type="email" 
                        placeholder="Email"
                        className="input"
                        style={{margin:'30px 0px'}}
                        value={email}
                        onChange={(e)=>setEmail(e.target.value)} />
                                        <span  style={{color:'red',fontSize:'14px'}}>{errormessage}</span>

                </div>
                <div className="send-email-div">
                    <button onClick={handleEmail} className="save-button">Send OTP</button>
                </div>

            </form>
        </Modal>
        <Modal isOpen={otpState} contentLabel="Example Modal" ariaHideApp={false} style={{content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        background:'white',
        width:'380px',
        height:'420px',
        maxHeight:'fit-content',
        padding:' 40px 30px',
        overflow:'auto',
        borderRadius: '10px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        },}}>
            <form>
                <div className='label-of-default-expense'>
                    <label className='lable-of-default-expense'>Email verification</label>
                    <RxCross2 onClick={()=>setOtpState(false)}/>
                </div>
                <div>
                    <input 
                        type='number' 
                        placeholder="OTP" 
                        className="input"
                        style={{margin:'30px 0px'}}
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        />
                </div>
                <span style={{fontSize:'14px',color:"#30B664",display:'flex',justifyContent:'center'}}>{otpMessage}</span>
                <div style={{display:'flex',justifyContent:'center',outline:'none'}}>
                    <button onClick={handleOTP} className="save-button">Verify OTP</button>
                </div>
            </form>
            <Toaster
  position="bottom-center"
  reverseOrder={false}
/>
        </Modal>
        
    </div> );
}

export default Verify_email;