import React from "react";
import { useEffect, useState } from "react";
import './reminder.css';
import { RxCross2 } from "react-icons/rx";
import DatePicker from 'react-datepicker';
import { FaPlus } from "react-icons/fa6";
import Table from 'react-bootstrap/Table';
import axios from "axios";
import Modal from 'react-modal';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Dropdown } from 'primereact/dropdown';
import CreatableSelect from 'react-select/creatable';
//import { colourOptions } from '../data';


function Reminder() {
    const [selectedCity, setSelectedCity] = useState(null);
    const [reminderTable,setReminderTable]=useState([]);
    const [reminderInvestTable,setReminderInvestTable]=useState([])
    const [showModal, setShowModal] = useState(false);
    const [reminder,setReminder]= useState([]);
    const [frequency,setFrequency]=useState("");
    const [updatedReminder, setUpdatedReminder] = useState({});
    const [date,setDate]=useState("")
    const [reminderMessage,setReminderMessage]=useState("")
    const [frequencyMessage,setFrequencyMessage]=useState("")
    const [dateMessage,setDateMessage]=useState("")
    const token = localStorage.getItem('user');
    const default_reminder_local= localStorage.getItem('default_reminder')
   
    const cities = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];
    const formatDateForExpense = (date) => {
        var options = { year: 'numeric', month: 'short', day: '2-digit' };
        let formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
        let parts = formattedDate.split(" ");
        return (parts[1] + "-" + parts[0] + "-" + parts[2]).replace(",","");
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                let config = {
                    method: 'post',
                    url: 'http://68.178.172.104:5010/api/Reminder',
                    headers: { 
                        'Authorization': `Bearer ${token}`
                    }
                };
                const response = await axios.request(config);
                
                setReminderTable(response.data.reminder);
                setReminderInvestTable(response.data.investment_reminder);
            } catch (error) {
                console.error("Error fetching data: ", error);
                toast.error("Something Went Wrong!");
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx

                } else if (error.request) {
                    // The request was made but no response was received
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            }
        };
        fetchData();
    }, []);
    
    const handledeletereminder = async (id) =>{

        if (id !== null) {
          const newItems = reminderTable.filter((item, index) => index !== id);
          setReminderTable(newItems);
          

      }
    }
    const handleSubmit = async (e) =>{
        e.preventDefault();
        const data= new FormData;
        data.append("reminder",JSON.stringify(reminderTable))
        data.append("investment_reminder",JSON.stringify(reminderInvestTable))
        
        let config={
            method:'post',
            url:'http://68.178.172.104:5010/api/Reminder',
            headers: { 
                'Authorization': `Bearer ${token}`
              },
            data:data
        }
        try {
            await axios.request(config);
            toast.success("Reminder Added");
        } catch (error) {
            toast.error("Reminder Not Submitted");
        }
        
    }
    useEffect(() => {
        setReminderMessage("")
    }, [reminder,showModal]);
    useEffect(() => {
        setFrequencyMessage("")
    }, [frequency,showModal]);
    useEffect(() => {
        setDateMessage("")
    }, [date,showModal]);

    const normalizeReminder = (value) => {
        if (typeof value === 'string') {
            return value;
        }
        if (value && typeof value.name === 'string') {
            return value.name;
        }
        return '';
    };
    const handleSave = (e)=>{
        e.preventDefault();
        let hasError=false
        if(reminder.length==0|| reminder===null|| reminder===undefined||reminder===""){
            setReminderMessage("Enter the reminder")
            hasError=true;
          }
          if(frequency.length==0||frequency===""||frequency===null||frequency===undefined){
           setFrequencyMessage("Enter Frequency")
           hasError=true;
          }
          if(date===""||date===null){
            setDateMessage("Enter Date")
            hasError=true;
          }
          if (hasError === true){
            hasError=false
            return
          }
        const AddReminder={reminder:reminder,date:formatDateForExpense(date),frequency:frequency}
        let Listitems = [...reminderTable, AddReminder]
        
        setReminderTable(Listitems)
        setDate("")
        setFrequency("")
        setReminder("")
        setShowModal(false)
    }
    const handleEditSave = (index) => {
        const updatedReminder = [...reminderTable];
        updatedReminder[index].isEditing = true;
        setReminderTable(updatedReminder);
     };
     const handleFrequencyChange = (e, index) => {
        const newFrequency = e.target.value;
        const updatedReminders = [...reminderTable];
        updatedReminders[index] = { ...updatedReminders[index], frequency: newFrequency};
        setReminderTable(updatedReminders);
    };
    const handleDateInEdit = (date,index) => {
        const newDate=formatDateForExpense(date)
        const updatedReminders = [...reminderTable];
        updatedReminders[index] = { ...updatedReminders[index], date: newDate};
        setReminderTable(updatedReminders);
    };
     const handleCloseEdit = (index) => {
        const updatedReminder = [...reminderTable];
        updatedReminder[index].isEditing = false;
        setReminderTable(updatedReminder);
     }
     const handleInputChange = (e, index) => {
        const newReminder = e.target.value;
        const updatedReminders = [...reminderTable];
        updatedReminders[index] = { ...updatedReminders[index], reminder: newReminder};
        setReminderTable(updatedReminders);
    };
     const handlePlus = (index) => {
        
          const updatedReminders = [...reminderTable];

          updatedReminders[index] = { ...updatedReminders[index], ...updatedReminder, isEditing: false};
          setReminderTable(updatedReminders);
          setUpdatedReminder({});
       };
       const handleCheckbox = (index)=>{
        let updatedReminderInvestment = [...reminderInvestTable];
        updatedReminderInvestment[index].reminder = !updatedReminderInvestment[index].reminder;
        setReminderInvestTable(updatedReminderInvestment);
        
       }
       
       const reminder_default = JSON.parse(default_reminder_local)
       
       //const activeReminders = reminder_default.filter(reminder => reminder.Active);
const activeReminders = reminder_default

    .filter(reminder => reminder.Active)
    .map((expense, index) => ({
        name: expense.reminder,
        code: expense.reminder
    }));
    if (activeReminders.length === 0) {
        activeReminders.push({
          name: "Add Reminder",
          code: "add_reminder",
          disabled: true
        });
      }

    return ( <div className="total-remider-div">
        <div className="content-box-in-reminder">
            <div className="top-box-in-reminder"><span className="Reminder-title">Reminder</span> <button className="save-button" style={{width:'170px',fontSize:'18px'}} onClick={()=>setShowModal(true)}>Add <FaPlus style={{marginLeft:'5px',height:'18px',width:'18px'}}/></button></div>
            <div className="table-in-reminder" style={{overflowX:'auto'}}>
            <Table >
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Date</th>
                        <th>Frequency</th>
                    </tr>
                </thead>
                <tbody>
                    {reminderTable!=null?(reminderTable.map((reminder,index)=>(
                        <tr key={index}>
                            <td className="Name-of-table-reminder">
                                {reminder.isEditing?(
                                                    <input
                                                    type="text"
                                                    className='input'
                                                    style={{width:'200px'}}
                                                    Value={reminder.reminder}
                                                    onChange={(e) => handleInputChange(e, index)}
                                                  />
                                                  
                                    

                                    
                                    ):(
                                    <div>{reminder.reminder}</div>)}
                            </td>
                            <td className="Date-of-table-reminder"> {reminder.isEditing?(
            <div>
                <DatePicker
                    placeholderText='Date'
                    showIcon
                    selected={moment(reminder['date']).toDate()}
                    toggleCalendarOnIconClick
                    onChange={(date) => handleDateInEdit(date,index)}
                    id="datepicker-in-edit"
                    
                    dateFormat="dd-MM-yyyy"
                    className='input'
                    showMonthDropdown
                    showYearDropdown
                    required
                    icon={<svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                          <path d="M13.5625 3.5H3.9375C3.17811 3.5 2.5625 4.11561 2.5625 4.875V14.5C2.5625 15.2594 3.17811 15.875 3.9375 15.875H13.5625C14.3219 15.875 14.9375 15.2594 14.9375 14.5V4.875C14.9375 4.11561 14.3219 3.5 13.5625 3.5Z" stroke="#5268F0" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M11.5 2.125V4.875" stroke="#5268F0" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M6 2.125V4.875" stroke="#5268F0" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M2.5625 7.625H14.9375" stroke="#5268F0" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>}
                    />
                </div>
                                    ):(
                                    <>{reminder.date}</>)}</td>
                            <td className="Frequency-of-table-reminder">{reminder.isEditing?(
                                    
                            <select className="input" style={{width:'200px'}} value={reminder.frequency} onChange={(e) => handleFrequencyChange(e, index)}>
                                
                                <option value="One-time">One-time</option>
                                {/*<option value="" disabled>---------------------------</option>*/}
                                <option value="Daily">Daily</option>
                                <option value="Weekly">Weekly</option>
                                <option value="Monthly">Monthly</option>
                                <option value="Quarterly">Quarterly</option>
                                <option value="Yearly">Yearly</option>
                            </select>
                                    
                                    ):(
                                    <>{reminder.frequency}</>)}</td>
                            <td className="td-icon-in-reminder" style={{paddingRight:'10px'}}>
                                {reminder.isEditing ?(
                                
                                <div  onClick={() => handlePlus(index)}><FaPlus/></div>
                                    ):
                                    (
                                <img src='edit-3.svg' onClick={()=>handleEditSave(index)}/>
                                )
                                
                                }</td>
                            <td className="td-icon-in-reminder" style={{paddingLeft:'10px'}}>
                                {reminder.isEditing?(
                                <RxCross2 onClick={()=> handleCloseEdit(index)}/>
                                    ):
                                    (
                                <img  src='trash-2.svg' onClick={()=>{handledeletereminder(index)}}/>)}</td>
                        </tr>
                    ))) : (<div>There is no reminder</div>)}
                    
                        
                        {/*
                        <td>
                            <select className="input" style={{width:'130px'}}>
                                <option value="" selected disabled>Select Frequency</option>
                                <option value="one-time">One-time</option>
                                <option value="" disabled>---------------------------</option>
                                <option value="daily">Daily</option>
                                <option value="weekly">Weekly</option>
                                <option value="monthly">Monthly</option>
                                <option value="quarterly">Quarterly</option>
                                <option value="yearly">Yearly</option>
                            </select>
                    </td>*/}
                        
                    
                </tbody>
            </Table>
            <Table>
                <thead>
                    <tr>
                        <th>Investment</th>
                        <th>Due date</th>
                        <th>Frequency</th>
                        
                    </tr>
                </thead>
                <tbody>
                    {reminderInvestTable!=null?(reminderInvestTable.map((reminder,index)=>(
                    <tr key={index}>
                        <td className="Name-of-table-reminder">{reminder.name}</td>
                        <td className="Date-of-table-reminder">{reminder.duedate}</td>
                        <td className="Frequency-of-table-reminder">{reminder.frequency}</td>
                        <td id="checkbox-in-reminder" className="checkbox-wrapper-13"><input type="checkbox" value={reminder.reminder} onChange={()=>handleCheckbox(index)}/></td>
                    </tr>))):(<div>There is no investment reminder</div>)}
                </tbody>
            </Table>
            <Modal isOpen={showModal} contentLabel="Example Modal" ariaHideApp={false} style={{content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                background:'white',
                width:'380px',
                height:'420px',
                padding:' 40px 30px',
                overflow:'none',
                borderRadius: '10px',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                },}}>
    <form className="form-addExpense">
      <div className="label-of-add-expense">
        <label>Add Reminder</label>
        <RxCross2 onClick={()=>setShowModal(false)} />
      </div>
      {/* <div><CreatableSelect/></div> */}
      <div style={{marginTop:'30px'}}>
      <Dropdown 
        value={reminder} 
        onChange={(e) => setReminder(normalizeReminder(e.target.value))} 
        options={activeReminders} 
        optionLabel="name" 
        editable 
        placeholder="Reminder" 
        className="w-full md:w-14rem" />
    </div>
      <div>
      {/* <div className='total-input-dropdown' style={{marginTop:'30px',border: '1px solid var(--Blue-10, rgba(82, 104, 240, 0.10)))'}}>
      <div>
      <select className='select-expense'  onChange={(e)=>setReminder(e.target.value)}>
        {console.log(reminder_default)}
        {reminder_default
      .filter(expense => expense.Active)
      .map((expense, index) => (
              <>{console.log(expense.reminder)}
              <option  key={expense.reminder} style={{ fontSize: '18px', padding: '20px' }}>
                  {expense.reminder}
              </option>
              </>
          
      ))
  }
        </select>
      </div>
      <input type="text" name="displayValue" id="displayValue" value={reminder} onChange={(e)=>setReminder(e.target.value)} placeholder="Name" />
     
  
      <input name="idValue" id="idValue" type="hidden"/>
      </div> */}
        <span className="error-message">{reminderMessage}</span>
        <div style={{paddingTop:'30px'}}>
        <DatePicker
                placeholderText='Date'
                    showIcon
                    selected={date}
                    onChange={(date) => setDate(date)}
                    id='date-popup-for-reminder'
                    toggleCalendarOnIconClick
                    
                    dateFormat="dd-MM-yyyy"
                    className='input'
                    showMonthDropdown
                    showYearDropdown
                    required
                    icon={<svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
        <path d="M13.5625 3.5H3.9375C3.17811 3.5 2.5625 4.11561 2.5625 4.875V14.5C2.5625 15.2594 3.17811 15.875 3.9375 15.875H13.5625C14.3219 15.875 14.9375 15.2594 14.9375 14.5V4.875C14.9375 4.11561 14.3219 3.5 13.5625 3.5Z" stroke="#5268F0" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.5 2.125V4.875" stroke="#5268F0" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6 2.125V4.875" stroke="#5268F0" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.5625 7.625H14.9375" stroke="#5268F0" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>}/>
      </div>
      <span className="error-message">{dateMessage}</span>
        <div style={{marginTop:'30px'}}>
        <select className="input" style={{width:'319px'}} value={frequency} onChange={(e)=>setFrequency(e.target.value)}>
        <option value="" selected disabled>Select Frequency</option>
                    <option value="One-time">One-time</option>
                    <option value="Daily">Daily</option>
                    <option value="Weekly">Weekly</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Quarterly">Quarterly</option>
                    <option value="Yearly">Yearly</option>
                </select>
        </div>
                <span className="error-message">{frequencyMessage}</span>
      </div>
      <div style={{marginTop:'30px'}}>
        <button className="save-button" onClick={handleSave}>Save</button>
      </div>
      </form>

            </Modal>
            
            </div>
            <button className="save-button" onClick={handleSubmit}>Submit</button>
        </div>
        <ToastContainer
position="bottom-right"
autoClose={1000}
limit={3}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
        
    </div> );
}

export default Reminder;