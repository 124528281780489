import React,{ useEffect, useState } from "react";
import Sidenav from '../components/side_nav';
import Topnav from '../components/top_nav';
import './budget.css';  
import moment from 'moment';
import Modal from 'react-modal';
import DatePicker from 'react-datepicker';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Stack from 'react-bootstrap/Stack';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { FaPlus } from "react-icons/fa6";
import DropdownRoll from "../components/budgetDropdown";
import Table from 'react-bootstrap/Table';
import { FiEdit3 } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import Popup from '../components/expenseSubmit';
import InvestPopup from '../components/investpopup';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Toaster } from 'react-hot-toast';

function Budget() {
  
  const [expensestate,setExpenseState]=useState(false);
  const [investstate,setInvestState]=useState(false);
  const [income,setIncome] = useState("")
const [expense,setExpense] = useState([])
const [investment,setInvestment] = useState([])
// const [selectedMonthYear, setSelectedMonthYear] = useState('');
const [message,setMessage]=useState("")
const [selectedInvestment, setSelectedInvestment] = useState('');     
const [ExpenseFormdata, setExpenseFormdata] = useState();
const [AmountFormdata, setAmountFormdata] = useState([]);
const [startDate, setStartDate] = useState("");
const [showInvests,setShowInvests] = useState([]);
const [selected, setSelected] = useState([]);
const [startDateInvest, setStartDateInvest] = useState(new Date());
const [expenseNameError,setExpenseNameError]=useState('');
const [expenseAmountError,setExpenseAmountError]=useState('');
const [expenseDateError,setExpenseDateError]=useState('')
const[currentMonth,setCurrentMonth]=useState();
const [currentYear,setCurrentYear]=useState();
const [items,setItems]=useState();
const default_expense = localStorage.getItem("default_expense") ;


const token = localStorage.getItem('user')
const getPreviousMonthYear = () => {
  const date = new Date();
  date.setMonth(date.getMonth() - 1);
  const options = { year: 'numeric', month: 'long' };
  return date.toLocaleDateString('en-US', options);
};
const [selectedMonthYear, setSelectedMonthYear] = useState(getPreviousMonthYear());

const handleExpenseAdd = (e) => {
  e.preventDefault()
      setExpenseState(true);
  }
  const handleClose =(e)=>{
    e.preventDefault()
      setExpenseState(false)
      setStartDate("")
  }

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long' };
    return new Date(dateString).toLocaleDateString('en-US', options);
   };
   const formatDateForExpense = (date) => {
    var options = { year: 'numeric', month: 'short', day: '2-digit' };
    let formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    let parts = formattedDate.split(" ");
    return (parts[1] + "-" + parts[0] + "-" + parts[2]).replace(",","");
};
  const handleMonthYearChange = async (e) => {

    setSelectedMonthYear(e.target.value);
  
 };
 useEffect(() => {
  const fetchdata=async()=>{
      const config = {
          method: "post",
          url: "http://68.178.172.104:5010/api/Budgetplaning",
          headers: { 
              'Authorization': `Bearer ${token}`
            }
        };
        try{
        const response = await axios.request(config);
        setIncome(response.data.income)
        setExpense(response.data.expense)
        setInvestment(response.data.investment)
        setShowInvests(response.data.invest)
        setCurrentMonth(response.data.current_month)
  setCurrentYear(response.data.current_year)}
  
  catch (error) {
    toast.error("Something Went Wrong!");
  }
  };
  fetchdata();
}, []);

const handlerollover = async (e) => {
  e.preventDefault();
  setSelectedMonthYear(formatDate(selectedMonthYear))
 
 const arr=selectedMonthYear.split(" ")
  const [month,year] = arr
  const data = new FormData();
  data.append("month",month)
  data.append("year",year)

  const config = {
      method: "post",
      url: "http://68.178.172.104:5010/api/budget_planing_roll",
      data:data,
      headers: { 
          'Authorization': `Bearer ${token}`,
          
        },
      
    };
    try{
    const response = await axios.request(config)
    if(response.data.status==="success"){
      
      setExpense(response.data.expense)
      //setIncome(response.data.income)
      setShowInvests(response.data.invest)
    }else{
      setExpense([])
      setShowInvests([])
    }
  }
  catch (error) {
    toast.error("Something Went Wrong!");
  }
      

};
const arr=selectedMonthYear.split(" ")
  const [month,year] = arr 
const handlebudget= async (e) =>{
  e.preventDefault();
  if(income===0||income===null){
    toast.error("Please Add income");
    return
  }
  const currentDate = new Date();
  
  const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
  const currentYear = currentDate.getFullYear();
  
  if (income.length==0 || income==null || income==NaN){
    alert("please enter income")
    return
  }
  // const total_expense = [...expense,...showInvests]
  
  const data = new FormData();
  data.append("income",income)
  data.append("expense",JSON.stringify(expense))
  data.append("invest",JSON.stringify(showInvests))
  data.append("month",currentMonth)
  data.append("year",currentYear)
  const config = {
      method: "post",
      url: "http://68.178.172.104:5010/api/budget_planing_submit",
      data:data,
      headers: { 
          'Authorization': `Bearer ${token}`,
          
        },
      
    };

    //const response = await axios.request(config)
    try {
      await axios.request(config);
      toast.success("Expense Added");
  } catch (error) {
    toast.error("Something Went Wrong!");
  }
}
const handledelete = (id) =>{
  if (id !== null) {
    const newItems = expense.filter((item, index) => index !== id);
    setExpense(newItems);
    
  }


}
const handleChange = (event) => {
  setSelectedInvestment(event.target.value);
 };

 const [isEditing, setIsEditing] = useState(false);

 const handleClick = () => {
  
    setIsEditing(!isEditing);
 };

 const handleChangeincome = (e) => {
    setIncome(e.target.value);
 };


 const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setIsEditing(!isEditing);
    }
 };
 useEffect(() => {
  setExpenseNameError("")
 }, [ExpenseFormdata,expensestate]);
 useEffect(() => {
  setExpenseAmountError("")
 }, [AmountFormdata,expensestate]);
 useEffect(() => {
  setExpenseDateError("")
 }, [startDate,expensestate]);
 const normalizeReminder = (value) => {
  if (typeof value === 'string') {
      return value;
  }
  if (value && typeof value.name === 'string') {
      return value.name;
  }
  return '';
};
 const handleOnSubmit = (e) =>{
  e.preventDefault();
  
          let hasError = false;
  
          if (!ExpenseFormdata|| ExpenseFormdata==null) {
              setExpenseNameError('Enter Expense');
              hasError = true;
          } 
  
          if (AmountFormdata.length === 0 || AmountFormdata === 0 || AmountFormdata==="") {
              setExpenseAmountError('Enter amount');
              hasError = true;
          } 
          if(startDate===""){
            setExpenseDateError('Enter Date');
            hasError = true;
          }
  
          if (hasError) {
            hasError=false;
              return;
          }
  const date=formatDateForExpense(startDate)
  
  const AddExpense={name:ExpenseFormdata,date:date,amount:AmountFormdata,saving:false}
  let Listitems = [...expense,AddExpense]

  
  setExpense(Listitems)
  setStartDate("")
  setExpenseFormdata("")
  setAmountFormdata("")
  setExpenseState(false)
  
 }

const handledeleteinvest = (id) =>{
  if (id !== null) {
    const newItems = showInvests.filter((item, index) => index !== id);
    setShowInvests(newItems);
  }
}
const handleInvestAdd = ()=>{
  setInvestState(true)
}
const handleInvestClose =()=>{
  setInvestState(false)
  setSelected("")
}
const handleInvestdata = (e)=>{
  e.preventDefault();
  if(selected.length == 0){
    setMessage('Please select an investment type')
    return
  }
  
 const data ={name:selected.name,date:formatDateForExpense(startDateInvest),amount:selected.periodic_amount}
 setShowInvests([...showInvests,data])
 setInvestState(false)
 setSelected("")
 
}
const handleOnClickforinvest = (investments)=>{
  setSelected(investments)
  setMessage("")
  

}
 
    return ( 
    <div className='budget-background'>
    <Container fluid>
        <Row>
            
            <Col className='top-nav-bar'>
            <div className='total-content'>
                <Row className='parent'>
                    <div className='top-grid'>
                        <div className='tops-top'><p className='top-grid-text'>Budget Period</p> 
                          <DropdownButton id="current-month" title={moment().format('MMM YYYY')}>
                              
                          </DropdownButton>
                        </div>
                        <div className='tops-top'><p className='top-grid-text'>Income</p><p className='top-grid-text' id="income">{isEditing ? (
        <input
          type="number"
          value={income}
          onChange={handleChangeincome}
          onBlur={handleClick}
          
          onKeyPress={handleKeyPress}
          autoFocus
        />
      ) : (
        <div className="income-first"onClick={handleClick}>{income}</div>
      )}<FiEdit3 onClick={handleClick} className='pen-icon'/></p></div>
                    </div>
                    <div className='top-grid'>
                        <div className='tops-top'><p className='top-grid-text'>Rollover from</p>
                        <DropdownRoll
                        selectedMonthYear={selectedMonthYear}
                        onChange={handleMonthYearChange}/>

                        </div>
                        
                        <div className='roll-submit'><button onClick={handlerollover} className='rollover-submit'>Submit</button></div>
                    </div>         
                </Row>
                <Row>
                    <div className='expense-title'>Expense</div>
                </Row>
                <Row className='parent'>
                    <div className='bottom-grid'>
                        <div className='bottoms-top'><p id='expense'>Expense</p><button id='add-in-expense' className='expense-add' onClick={handleExpenseAdd}>Add <FaPlus/></button></div>
                        <Popup
                        expensestate={expensestate}
                        onClose={handleClose}
                        onAdd={handleOnSubmit}
                        value={ExpenseFormdata}
                        expense={(e)=>setExpenseFormdata(normalizeReminder(e.target.value))}
                        default_expense={default_expense}
                        amount={(e)=>setAmountFormdata(parseInt(e.target.value))}
                        startDate={startDate}
                        datechange={(date) => setStartDate(date)}
                        expenseNameError={expenseNameError}
                        expenseAmountError={expenseAmountError}
                        expenseDateError={expenseDateError}
                        />
                        <div className='bottoms-bottom'>
                        <Table responsive className='expense-table'>
                          <thead>
                            <tr>
                              <th>Expense</th>
                              <th>Date</th>
                              <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                          {(expense != null && expense.length!=0) ?(expense.map((item, index) => (
                            <tr key={index} >
                              <td className="Name-of-table-expense" >{item['name']}</td>
                              <td className="Date-of-table-expense" >{item['date']}</td>
                              <td className="Amount-of-table-expense" > {item['amount']}</td>
                              <td className="cross-of-table-expense" onClick={() => handledelete(index)}><RxCross2 className="cross" alt="close"/></td>
                            </tr>
                          ))):(<>There is no expense </>)}
                            
                          </tbody>
                        </Table>
                        </div>
                    </div>
                    <div className='bottom-grid'>
                        <div className='bottoms-top'>
                            <p id='investment'>Investments</p> 
                            <div><button id='add-in-expense' className='expense-add' onClick={handleInvestAdd}>Add <FaPlus/></button></div>
                            <Modal isOpen={investstate} contentLabel="Example Modal" ariaHideApp={false} style={{content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    background:'white',
    width:'380px',
    height:'420px',
    padding:' 40px 30px',
    overflow:'none',
    borderRadius: '10px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },}}> 
             <form className="form-addExpense">
      <div className="label-of-add-expense">
        <label>Add Investment</label>
        <RxCross2 onClick={handleInvestClose} />
      </div>
      <br />

      <DropdownButton
        id="dropdown-basic-button-for-invest"
        title={selectedInvestment || "Investments"}
        className="dropdown-of-investment"
      >
        {investment.length === 0 ? (
          <Dropdown.Item onClick={() => console.log("Add investment")}>
            Add Investment
          </Dropdown.Item>
        ) : (
          investment.map((investments) => (
            <Dropdown.Item
              key={investments.name}
              value={investments}
              id="invest-drop"
              onClick={() => handleOnClickforinvest(investments)}
            >
              {investments.name} - {investments.frequency} - {investments.periodic_amount}
            </Dropdown.Item>
          ))
        )}
      </DropdownButton>
      <br />
     <span className="errormessageStyle"> {message}</span>
     <p>Name:{selected.name},Amount:{selected.periodic_amount}</p>
      <DatePicker
      showIcon
        placeholderText="Date"
        selected={startDateInvest}
        onChange={(date) => setStartDateInvest(date)}
        dateFormat="dd-MMM-yyyy"
        className="input"
        showMonthDropdown
        showMonthYearDropdown
        required
        icon={<svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
        <path d="M13.5625 3.5H3.9375C3.17811 3.5 2.5625 4.11561 2.5625 4.875V14.5C2.5625 15.2594 3.17811 15.875 3.9375 15.875H13.5625C14.3219 15.875 14.9375 15.2594 14.9375 14.5V4.875C14.9375 4.11561 14.3219 3.5 13.5625 3.5Z" stroke="#5268F0" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.5 2.125V4.875" stroke="#5268F0" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6 2.125V4.875" stroke="#5268F0" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.5625 7.625H14.9375" stroke="#5268F0" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>}
        />
      <br />
      
      <button className="save-button" onClick={handleInvestdata} >
        Save
      </button>
      <br />
    </form>


        </Modal>
                            
                        </div>
                        <div className='bottoms-bottom'><Table responsive className='expense-table'>
                          <thead>
                            <tr>
                              <th>Expense</th>
                              <th>Date</th>
                              <th>Amount</th>
                            </tr> 
                          </thead>
                          <tbody>
                          {showInvests.length!= 0 ?(showInvests.map((item, index) => (
                            <tr key={index} >
                              <td className="Name-of-table-expense">{item['name']}</td>
                              <td >{item['date']}</td>
                              <td >{item['amount']}</td>
                              <td onClick={() => handledeleteinvest(index)}><RxCross2 className="cross" alt="close"/></td>
                            </tr>
                          ))):(<>There is no investment </>)}
                            
                          </tbody>
                        </Table></div>
                    </div>
                </Row>
                <Row>
                   <div className="save-button-div" > <button className='save-button' onClick={handlebudget}>Submit</button></div>
                </Row>
                </div>
            </Col>
              
        </Row>
    </Container>
    <ToastContainer
position="bottom-right"
autoClose={1000}
limit={3}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"

/>
    
    </div> );
}

export default Budget;