import react, { useState,useEffect } from 'react';
import axios from "axios";
import './forgetpass.css'
import { useNavigate } from "react-router-dom";
import React from 'react';
import { Toaster } from 'react-hot-toast';
import { ToastContainer, toast } from 'react-toastify';

function Forgetpassword(){
    const [username,SetUsername ]=useState("");
    const [otp,SetOtp]=useState("");
    const [isDisabled, setIsDisabled] = useState(true);
    const [hotp,setHotp]=useState(true);;
    const [message,setMessage]=useState("")
    const navigate = useNavigate();
    const [password, SetPassword] = useState("");
    const [confirmpassword, SetConfirmpassword] = useState("");
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const resetotp= async(e)=>{
      e.preventDefault();
        if (username.length === 0) {
            setMessage('Please Enter userId')
            return
      }
      const data = new FormData();
      data.append("userid",username)
      const config = {
        method: "post",
        url: "http://68.178.172.104:5010/api/forget_password_otp",
        data: data,
      };
      try {
        const response = await axios.request(config);
        if(response.data.message==="Email sent successfully!"||response.data.message==="OTP successfully sent"){
        localStorage.setItem('username',username);
        navigate("/reset"); 
        }
        else{
          setMessage(response.data.message)
        }
      }
      catch (error)            
      { toast.error("Something Went Wrong!");}

    }
   
    return(
        <div className='total-login1'>
         <div className='login-form2'>
          <form className='form-op' method='post' >
            <label className='label'>Username:<br/><input className='input' type="text" value={username} onChange={(e) => SetUsername(e.target.value)} placeholder='Enter Username or Number' /></label>
            <div style={{padding:'10px 15px'}}>
            <button className='save-button' onClick={resetotp}>Send OTP</button>
            </div>
            <p className='error-style'>{message}</p>

          
          </form>
        </div>  
        {/*<div className='login-form'>
          <form className='form-op' method='post' >

          
            <label className='label'>Username:<br/><input className='inputs' type="text" value={username} onChange={(e) => SetUsername(e.target.value)} placeholder='Enter Username or Number' /></label><br />
            <button className='button-29' onClick={resetotp}>Send OTP</button><br/>
            <p>{message}</p>
            <label  className='label'>OTP:<br/><input className='inputs' id='otp' type="number" value={otp} onChange={(e) => SetOtp(e.target.value)} placeholder='Enter OTP' /></label><br />
            <button  onClick={handleOTP} className='button-29' >Submit OTP</button>
          
          </form>
    </div>*/}
          <ToastContainer />

        </div>
    );
}

export default Forgetpassword;