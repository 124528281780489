

import Modal from 'react-modal';
import React from 'react';
import { RxCross2 } from "react-icons/rx";
import react,{useEffect,useState,useRef} from 'react';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import './default_expense.css';
import { FiEdit3 } from "react-icons/fi";
import { FaPlus } from "react-icons/fa6";
import { Toaster } from 'react-hot-toast';
import { toast } from 'react-hot-toast';


function Default_expense(props) {

    const token = localStorage.getItem('user')
    const [expired, setExpired] = useState(false);
    const [expenses, setExpenses] = useState([]);
    const [editableExpenses, setEditableExpense]=useState([]);
    const [addInput,setAddInput]=useState(false )
    const [addexpense,setAddExpense]=useState("")
    const [message,setMessage]=useState("")

    useEffect(()=>{
        const fetchdata=async()=>{
            const data = {
                "data": {
                  "expense": ""
                },
                "toUpdate": "expense"
              };

        const config = {
               method: "post",
               url: "http://68.178.172.104:5010/api/settings",
               headers: { 
                   'Authorization': `Bearer ${token}`,  
               },
               data : data  
             };
             try{
    const response=await axios.request(config)
      setExpenses(response.data.default_expenses)
      setExpired(false);
      setEditableExpense(response.data.editable_expense)
    }
      catch (error) {
        toast.error("Something Went Wrong!");

        if (error.response.status === 401) {
          setExpired(true);
        }
      }
      
            }
            fetchdata();
           

            
      },[]);

      const addExpense = () => {
        if(addexpense.length==0){
          
          setMessage("Please enter an expense")
          return
        }
        if (editableExpenses.length < 6) {
          const newExpense = {
            Active: false,
            expense: addexpense,
          };
          setEditableExpense([...editableExpenses, newExpense]);
        }
        setAddExpense("")
     };
            
     const editExpense = (index, updatedExpense) => {
        const updatedExpenses = editableExpenses.map((expense, i) =>
          i === index ? { ...expense, ...updatedExpense } : expense
        );
        setEditableExpense(updatedExpenses);
     };
    
     const deleteExpense = (index) => {
        const filteredExpenses = editableExpenses.filter((expense, i) => i !== index);
        setEditableExpense(filteredExpenses);
     };


      const handleCheckboxChange = (index) => {
        let updatedExpenses = [...expenses];
        updatedExpenses[index].Active = !updatedExpenses[index].Active;
        setExpenses(updatedExpenses);
     };
     const handleCheckboxChangefor = (index) => {
      let updatedExpenses = [...editableExpenses];
      updatedExpenses[index].Active = !updatedExpenses[index].Active;
      setEditableExpense(updatedExpenses);
   };
    const handleSubmit = async (e)=>{
      e.preventDefault();
      let list =[[...expenses],[...editableExpenses]]
      const data = {
        "data": {
          "expense": list
        },
        "toUpdate": "expense"
      };

const config = {
       method: "post",
       url: "http://68.178.172.104:5010/api/settings",
       headers: { 
           'Authorization': `Bearer ${token}`,  
       },
       data : data  
     };
     try {
      const response=await axios.request(config)
      const new_default_expense = list.flat()
      toast.success('Default Expense Added Successfully!');

      localStorage.setItem('default_expense',JSON.stringify(new_default_expense))
  } catch (error) {
  }
     
    
    

  }
  const add =(e)=>{
    e.preventDefault();
    setAddExpense("")
    setAddInput(true)
  }
    return ( <div>
         <Modal isOpen={props.state} contentLabel="Example Modal" ariaHideApp={false} style={{content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    background:'white',
    width:'380px',
    height:'420px',
    maxHeight:'fit-content',
    padding:' 40px 30px',
    overflow:'auto',
    borderRadius: '10px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },}}> 
  <form style={{display:'inline',flexDirection:'row'}}>
  <div className='label-of-default-expense'><label className='lable-of-default-expense'>Default Expense</label><RxCross2 onClick={props.close} /></div><br/>
    

    <Table>

      <tbody>

      {expenses.map((expense,index) => (
        <tr key={index}>
          
          <td>{expense.expense}</td>
          <td id='td-of-check-box-in-default-expense' className="checkbox-wrapper-13">
          <label class="green-checkbox">
            <input
              type="checkbox"
              id="c1-13"
              checked={expense.Active}
              onChange={() => handleCheckboxChange(index)}
            />
             
            </label>
          </td>
        </tr>
      ))}
      {editableExpenses.map((expense,index)=>(
        <tr key={index}>
          <td>{expense.expense}</td>
          <td id='td-of-check-box-in-default-expense' className="checkbox-wrapper-13">
          <label class="green-checkbox">
          <input
              type="checkbox"
              id="c1-13"
              checked={expense.Active}
              onChange={() => handleCheckboxChangefor(index)}
            />

            </label></td>

             <td onClick={() => deleteExpense(index)} style={{color:'red'}}><RxCross2/></td>
        </tr>
      ))}
      
        </tbody>
       
    </Table>
    

    {addInput?<div className='ADD-EXPENSE-DEFAULT' >
          
      <input type='text' className='input-add-default-expense' value={addexpense} onChange={(e)=>{setAddExpense(e.target.value); setMessage("")}}/>
      <FaPlus 
      onClick={addExpense}
      className='plus'
      style={{marginRight:'20px'}}/>
      
        <RxCross2 onClick={()=>{setAddInput(false); setMessage("")}} style={{color:'red'}}/>
        
        </div>:null
      }
  <div style={{color:'red'}}>{message}</div>
  <div className='add-expense-button-div'>
        <button className='Add-expense-in-default' onClick={add}>Add +</button>
        </div>
        

        
  <div className='save-button-div'>
  <button className='save-button-for-default-expense' onClick={handleSubmit}>Save</button>
  </div>
  </form>
  <Toaster
  position="bottom-center"
  reverseOrder={false}
/>
        </Modal>
        

    </div> );
    
}

export default Default_expense;