import React from "react";
import axios from "axios";
import { useState,useEffect } from "react";
import Modal from 'react-modal';
import { RxCross2 } from "react-icons/rx";
import './update_email.css';
import { Toaster } from 'react-hot-toast';
import { toast } from 'react-hot-toast';

function Update_whatsapp(props) {
    const [whatsapp,setWhatsapp]=useState("");
    const token = localStorage.getItem('user');
    const [message,setMessage]=useState("");
    const [expired, setExpired] = useState(false);
    useEffect(() => {
        if (message) {
          const timeoutId = setTimeout(() => {
            setMessage(null);
            
          }, 5000); // 5000ms = 5 seconds
    
          // Clear the timeout if the component is unmounted or the message changes
          return () => clearTimeout(timeoutId);
        }
      }, [message]);
      useEffect(() => {
        setMessage("")
      }, [whatsapp]);

    const handleUpdate = async(e)=>{
        e.preventDefault();
       
        if (whatsapp<10) {
          setMessage('Enter your 10 digit Mobile Number')
          return
        }
          if(whatsapp.length===0 && whatsapp===''){
              setMessage("Please enter whatsapp number")
              return
          }
    
        let data=
            {
                "data": {"new_number":whatsapp},
                 "toUpdate":"whatsapp"
             }
             const config = {
                method: "post",
                url: "http://68.178.172.104:5010/api/settings",
                headers: { 
                    'Authorization': `Bearer ${token}`,  
                },
                data : data  
              };
              try{
               const response=await axios.request(config)
               if(response.data.status === 'success' && response.data.message==="whatsapp number and userid is updated"){
                // setMessage(response.data.message);
                toast.success("Whatsapp updated successfully!");

                localStorage.setItem('user',response.data.access_token)
                localStorage.setItem('whatsapp_verified',false);
                setWhatsapp("")
               }
               else if(response.data.status === 'success' && response.data.message==="whatsapp updated successfully"){
                localStorage.setItem('whatsapp_verified',false);
                toast.success("Whatsapp updated successfully!");

                // setMessage(response.data.message); 
                setWhatsapp("") 
               }
               else if(response.data.message==='Email not verified'){
                // setMessage(response.data.message);
                toast.error("Whatsapp not updated!!");

                setWhatsapp("")
               }
               else {
                toast.error("Something Went Wrong!");
            }
             }
             catch (error) {
              toast.error("Something Went Wrong!");

               if (error.response.status === 401) {
                 setExpired(true);
               }
           }
       
        
    }
        return ( <div>
        <Modal isOpen={props.state} contentLabel="Example Modal" ariaHideApp={false} style={{content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        background:'white',
        width:'380px',
        height:'420px',
        maxHeight:'fit-content',
        padding:' 40px 30px',
        overflow:'auto',
        borderRadius: '10px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        },}}>
            <form>
            <div className='label-of-default-expense'>
                    <label className='lable-of-default-expense'>Update Whatsapp Number</label>
                    <RxCross2 onClick={props.close} />
                </div>
                <div className="input-div-in-update-email">
                    <input 
                        type='number' 
                        placeholder="Whatsapp Number"
                        value={whatsapp}
                        onChange={(e)=>setWhatsapp(e.target.value)}
                        className="input"/>
                </div>
                <span className="error-message">{message}</span>
                <div className="updateButton-in-update-email">
                    <button className="save-button" style={{outline:'none'}} onClick={handleUpdate}>Update</button>
                </div>
            </form>
        </Modal>
        <Toaster
  position="bottom-center"
  reverseOrder={false}
/>
    </div> );
}

export default Update_whatsapp;