import Modal from 'react-modal';
import React from 'react';
import { RxCross2 } from "react-icons/rx";
import react,{useEffect,useState,useRef} from 'react';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import './default_reminder.css';
import { FiEdit3 } from "react-icons/fi";
import { FaPlus } from "react-icons/fa6";
import { Toaster } from 'react-hot-toast';
import { toast } from 'react-hot-toast';

function Default_reminder(props) {

    const token = localStorage.getItem('user')
    const [expired, setExpired] = useState(false);
    const [reminders, setreminders] = useState([]);
    const [editablereminders, setEditablereminder]=useState([]);
    const [addInput,setAddInput]=useState(false )
    const [addreminder,setAddreminder]=useState("")
    const [message,setMessage]=useState("")

    useEffect(()=>{
        const fetchdata=async()=>{
            const data = {
                "data": {
                  "reminder": ""
                },
                "toUpdate": "reminder"
              };

        const config = {
               method: "post",
               url: "http://68.178.172.104:5010/api/settings",
               headers: { 
                   'Authorization': `Bearer ${token}`,  
               },
               data : data  
             };
             try{
    const response=await axios.request(config)
      setreminders(response.data.default_reminder)
      setExpired(false);
      setEditablereminder(response.data.editable_reminder)
    }
      catch (error) {
        toast.error("Something Went Wrong!");

        if (error.response.status === 401) {
          setExpired(true);
        }
      }
    //   try {
    //     await axios.request(config);
    //     toast.success("Default Reminder is Added!!");
    // } catch (error) {
    //     toast.error("Reminder not Added");
    // }
            }
            fetchdata();

            
      },[]);

      const addReminder = () => {
        if(addreminder.length==0){
          
          setMessage("Please enter an reminder")
          return
        }
        if (editablereminders.length < 6) {
          const newreminder = {
            Active: false,
            reminder: addreminder,
          };
          setEditablereminder([...editablereminders, newreminder]);
        }
        setAddreminder("")
     };
            
     const editreminder = (index, updatedreminder) => {
        const updatedreminders = editablereminders.map((reminder, i) =>
          i === index ? { ...reminder, ...updatedreminder } : reminder
        );
        setEditablereminder(updatedreminders);
     };
    
     const deletereminder = (index) => {
        const filteredreminders = editablereminders.filter((reminder, i) => i !== index);
        setEditablereminder(filteredreminders);
     };


      const handleCheckboxChange = (index) => {
        let updatedreminders = [...reminders];
        updatedreminders[index].Active = !updatedreminders[index].Active;
        setreminders(updatedreminders);
     };
     const handleCheckboxChangefor = (index) => {
      let updatedreminders = [...editablereminders];
      updatedreminders[index].Active = !updatedreminders[index].Active;
      setEditablereminder(updatedreminders);
   };
    const handleSubmit = async (e)=>{
      e.preventDefault();
      let list =[[...reminders],[...editablereminders]]
      const data = {
        "data": {
          "reminder": list
        },
        "toUpdate": "reminder"
      };

const config = {
       method: "post",
       url: "http://68.178.172.104:5010/api/settings",
       headers: { 
           'Authorization': `Bearer ${token}`,  
       },
       data : data  
     };
     try{
const response=await axios.request(config)
const new_default_reminder = list.flat()
toast.success("Default Reminder is Added!!");

localStorage.setItem('default_reminder',JSON.stringify(new_default_reminder))
    }
    catch (error) {
      if (error.response.status === 401) {
        setExpired(true);
      }
    }
  }
  const add =(e)=>{
    e.preventDefault();
    setAddInput(true)
  }
    return ( <div>
         <Modal isOpen={props.state} contentLabel="Example Modal" ariaHideApp={false} style={{content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    background:'white',
    width:'380px',
    height:'420px',
    maxHeight:'fit-content',
    padding:' 40px 30px',
    overflow:'auto',
    borderRadius: '10px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },}}> 
  <form style={{display:'inline',flexDirection:'row'}}>
  <div className='label-of-default-reminder'><label className='lable-of-default-reminder'>Default Reminder</label><RxCross2 onClick={props.close} /></div><br/>
    <Table>

      <tbody>

      {reminders.map((reminder,index) => (
        <tr key={index}>
          
          <td>{reminder.reminder}</td>
          <td id='td-of-check-box-in-default-reminder' className="checkbox-wrapper-13">
            
          <label class="green-checkbox">
            <input
              type="checkbox"
              id="c1-13"
              checked={reminder.Active}
              onChange={() => handleCheckboxChange(index)}
            />
             
            </label>
          </td>
        </tr>
      ))}
      {editablereminders.map((reminder,index)=>(
        <tr key={index}>
          <td>{reminder.reminder}</td>
          <td id='td-of-check-box-in-default-reminder' className="checkbox-wrapper-13">
          <label class="green-checkbox">
          <input
              type="checkbox"
              id="c1-13"
              checked={reminder.Active}
              onChange={() => handleCheckboxChangefor(index)}
            />

            </label></td>

             <td onClick={() => deletereminder(index)} style={{color:'red'}}><RxCross2/></td>
        </tr>
      ))}
      
        </tbody>
       
    </Table>
    

    {addInput?<div className='ADD-reminder-DEFAULT' >
          
      <input type='text' className='input-add-default-reminder' value={addreminder} onChange={(e)=>{setAddreminder(e.target.value); setMessage("")}}/>
      <FaPlus 
      onClick={addReminder}
      className='plus'
      style={{marginRight:'20px'}}/>
      
        <RxCross2 onClick={()=>{setAddInput(false); setMessage("")}} style={{color:'red'}}/>
        
        </div>:null
      }
  <div style={{color:'red'}}>{message}</div>
  <div className='add-reminder-button-div'>
        <button className='Add-reminder-in-default' onClick={add}>Add +</button>
        </div>
        

        
  <div className='save-button-div'>
  <button className='save-button-for-default-reminder' onClick={handleSubmit}>Save</button>
  <Toaster
  position="bottom-center"
  reverseOrder={false}
/>
  </div>
  
  </form>
  
        </Modal>
    </div> );
}

export default Default_reminder;